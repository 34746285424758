import { createTranslator } from '../translate.mjs'
import { uiStrings } from '../i18n/uiStrings.mjs'

const translators = {
  ru: createTranslator(uiStrings, 'ru'),
  en: createTranslator(uiStrings, 'en'),
  sr: createTranslator(uiStrings, 'sr'),
  ua: createTranslator(uiStrings, 'ua'),
}

export const useTranslator = (language) => {
  return translators[language]
}
