const m_to_fix = [
  'a',
  'b',
  'v',
  'g',
  'd',
  'đ',
  'e',
  'ž',
  'z',
  'i',
  'j',
  'k',
  'l',
  'lj',
  'm',
  'n',
  'nj',
  'o',
  'p',
  'r',
  's',
  't',
  'ć',
  'u',
  'f',
  'h',
  'c',
  'č',
  'dž',
  'š',
  'A',
  'B',
  'V',
  'G',
  'D',
  'Đ',
  'E',
  'Ž',
  'Z',
  'I',
  'J',
  'K',
  'L',
  'LJ',
  'M',
  'N',
  'NJ',
  'O',
  'P',
  'R',
  'S',
  'T',
  'Ć',
  'U',
  'F',
  'H',
  'C',
  'Č',
  'DŽ',
  'Š',
]

const m_fixed = [
  'а',
  'б',
  'в',
  'г',
  'д',
  'ђ',
  'е',
  'ж',
  'з',
  'и',
  'ј',
  'к',
  'л',
  'љ',
  'м',
  'н',
  'њ',
  'о',
  'п',
  'р',
  'с',
  'т',
  'ћ',
  'у',
  'ф',
  'х',
  'ц',
  'ч',
  'џ',
  'ш',
  'А',
  'Б',
  'В',
  'Г',
  'Д',
  'Ђ',
  'Е',
  'Ж',
  'З',
  'И',
  'Ј',
  'К',
  'Л',
  'Љ',
  'М',
  'Н',
  'Њ',
  'О',
  'П',
  'Р',
  'С',
  'Т',
  'Ћ',
  'У',
  'Ф',
  'Х',
  'Ц',
  'Ч',
  'Џ',
  'Ш',
]

export const latinToCyrilic = (string) => {
  let dObj = {}
  let m_output = ''
  m_to_fix.forEach((m, i) => {
    if (m.length === 2) dObj[m] = m_fixed[i] //Assign Replacements for double letters in the object
  })

  let doubles = ['l', 'n', 'd', 'L', 'N', 'D'] //Array of 1st letter of all double letters

  for (let i = 0; i < string?.length; i += 1) {
    let char = string[i]

    if (doubles.includes(char)) {
      //Check if char exist in the doubles array
      if (string[i + 1] !== undefined) {
        //only if the next value exists (not undefined)!
        let char2 = string[i + 1] //Get the immediate next charcater.

        if (char + char2 in dObj) {
          //Check if char+char2 exists in the object
          m_output += dObj[char + char2] //If it is, add the replacement to the output
        } else {
          if (char2 + string[i + 2] in dObj) {
            //Check if char2+char2+1 exists in the object (example 'dnj' for poslednja)
            m_output += m_fixed[m_to_fix.indexOf(char)]
            m_output += dObj[char2 + string[i + 2]] //If it is, add the replacement to the output
          } else {
            //edge case for 'њј','љј','џж','ЊЈ','ЉЈ','ЏЖ'
            const criticalChars = ['њј', 'љј', 'џж', 'ЊЈ', 'ЉЈ', 'ЏЖ']
            criticalChars.some((element) => {
              if (m_output.includes(element)) {
                m_output = m_output.replace(element, element.slice(0, -1))
              }
            })

            let ind = m_to_fix.indexOf(char) //Else add replacement of each char individually
            m_output += m_fixed[ind]

            ind = m_to_fix.indexOf(char2)
            m_output += m_fixed[ind]
          }
        }
        i += 1 //Manually increase index by 1 since, we also checked for next char (char2) to avoid repition
      } else {
        //if the next value is undefined then do as usual
        let ind = m_to_fix.indexOf(char)
        m_output += m_fixed[ind]
      }
    } else {
      let ind = m_to_fix.indexOf(char) //Else if char doesn't exist in doubles array, get the index of that char from m_to_fix array
      m_output += m_fixed[ind] ? m_fixed[ind] : char //add the respective replacement of from the m_fixed array
    }
  }

  return m_output
}
