import { LoadingOutlined } from '@ant-design/icons'
import {
  Form,
  Select,
  Checkbox,
  InputNumber,
  Spin,
  Button,
  Row,
  Col,
  Divider,
  Radio,
  Typography,
  Slider
} from 'antd'
import { useState, useEffect, useCallback } from 'preact/hooks'
import difference from 'lodash/fp/difference.js'
import { latinToCyrilic } from '../../helpers/latinToCyrilic.mjs'
import { useTranslator } from '../translate.js'
import env from '../assets/env.json'

const { HOST_SERVER } = env

const rooms = [
  { label: 'Studio', value: 'Studio' },
  { label: '1', value: '1', },
  { label: '2', value: '2', },
  { label: '3', value: '3', },
  { label: '4+', value: '4+', },
]

const advertiser = [
  { label: 'None', value: '' },
  { label: 'Owner', value: 'Owner' },
  { label: 'Agency', value: 'Agency' }
]

let rawStaticData
let staticData = {}

export const FilterPage = ({ externalId, editId }) => {
  const [isReady, setReady] = useState(false)
  const [form] = Form.useForm()
  const [filter, setFilter] = useState({ type: [1], city: [] })
  const [roomsAreHidden, setRoomsHidden] = useState(false)
  const [additionalAreHidden, setAdditionalAreHidden] = useState(true)
  const [dailyCounter, setDailyCounter] = useState(0)
  const [allMunsChecked, setAllMunsChecked] = useState(true)
  const [locale, setLocale] = useState('ru')

  const t = useTranslator(locale)

  useEffect(() => {
    const fetchData = async () => {
      const responses = await Promise.all([
        fetch(`${HOST_SERVER}/api/v1/users/${externalId}`),
        fetch(`${HOST_SERVER}/api/v1/filter/props`),
      ])

      const userData = await responses[0].json()
      rawStaticData = await responses[1].json()

      setLocale(userData.language)

      if (userData.filter) setFilter(userData.filter)
      if (userData.filter?.municipality) setAllMunsChecked(false)
      if (userData.filter && Object.keys(userData.filter).some(key => [
        'furniture',
        'heating',
        'advertiser',
        'sizeMin',
        'sizeMax',
        'floorsMin',
        'floorsMax',
        'arePetsAllowed',
      ].includes(key) && userData.filter[key] !== null && typeof userData.filter[key] !== 'undefined')) {
        setAdditionalAreHidden(false)
      }

      let localeField
      let shouldConvertToCyrilic

      switch (userData.language) {
        case 'ru':
          localeField = 'titleRu'
          shouldConvertToCyrilic = true
          break

        case 'en':
          localeField = 'titleEn'
          shouldConvertToCyrilic = false
          break

        case 'sr':
          localeField = 'title'
          shouldConvertToCyrilic = false
          break

        case 'ua':
          localeField = 'titleUa'
          shouldConvertToCyrilic = true
          break
      }

      for (const nodeName in rawStaticData) {
        if (nodeName === 'municipalities') {
          staticData.munByCityId = {}

          rawStaticData.municipalities.forEach((record) => {
            if (!staticData.munByCityId[record.cityId]) {
              staticData.munByCityId[record.cityId] = []
            }

            staticData.munByCityId[record.cityId].push({
              label: record[localeField]
                ? record[localeField]
                : shouldConvertToCyrilic
                  ? latinToCyrilic(record.title)
                  : record.title,
              value: record.id,
            })
          })

          console.log('munByCityId', staticData.munByCityId)
        } else {
          staticData[nodeName] = rawStaticData[nodeName].map((record) => {
            return {
              label: record[localeField]
                ? record[localeField]
                : shouldConvertToCyrilic
                  ? latinToCyrilic(record.title)
                  : record.title,
              value: record.id,
            }
          })

          console.log(nodeName, staticData[nodeName])
        }
      }

      window.Telegram.WebApp.ready()
      window.Telegram.WebApp.MainButton.setText(useTranslator(userData.language)('filter.submit'))
      window.Telegram.WebApp.MainButton.show()
      window.Telegram.WebApp.expand()
    }

    fetchData().then(() => setReady(true))
  }, [])

  /*useEffect(() => {
    const timeout = setTimeout(async () => {
      if (filter.city.length === 0 || filter.type.length === 0) return

      const f = async () => {
        const res = await fetch(`${HOST_SERVER}/api/v1/filter/counter`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ filter })
        })

        const { count } = await res.json()

        return count || 0
      }

      f().then(setDailyCounter)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [filter])*/

  useEffect(() => {
    window.Telegram.WebApp.onEvent('mainButtonClicked', handleFormSubmit)

    return () => window.Telegram.WebApp.offEvent('mainButtonClicked', handleFormSubmit)
  }, [filter])

  const handleFormChange = useCallback((changedData, data) => {
    let filter = data

    for (const key in filter) {
      if (key === 'city' && filter.municipality) {
        let allAvailableMuns = []

        filter.city.forEach((cityId) => {
          allAvailableMuns = [...allAvailableMuns, staticData.munByCityId[cityId].map(_ => _.value)].flat()
        })

        filter.municipality = difference(allAvailableMuns, difference(allAvailableMuns, filter.municipality))
      }

      if (key === 'city' || key === 'type') continue

      if (filter[key] === undefined || filter[key] === false || (Array.isArray(filter[key]) && filter[key].length === 0)) {
        delete filter[key]
      }
    }

    console.log(filter)

    setRoomsHidden(data.type.length === 1 && data.type[0] === 3)
    setFilter(filter)
  }, [filter])

  const handleFormSubmit = useCallback(() => {
    const handler = async () => {
      window.Telegram.WebApp.MainButton.showProgress()

      await fetch(`${HOST_SERVER}/api/v1/filter/${editId}/${externalId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ filter })
      })

      window.Telegram.WebApp.MainButton.hideProgress()
      window.Telegram.WebApp.close()
    }

    /*
    TODO: вдуплить, почему dailyCounter тут такой не новый
    if (dailyCounter > 50) {
      window.Telegram.WebApp.showConfirm(t('filter.messages.to-many'), () => {
        handler()
      })
    } else {
      handler()
    }
    */

    handler()
  }, [filter])

  const setCityPreset = useCallback((id) => {
    form.setFieldValue('city', [id])
    handleFormChange({city: [id]}, { ...form.getFieldsValue(true), city: [id] })
    form.validateFields(['city'])
  }, [])

  if (!isReady) {
    return (
    <div className="layout">
      <div className="content">
        <div style={{ textAlign: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      </div>
    </div>
    )
  }

  const municipalitiesAvailable = filter.city.filter((cityId) => staticData.munByCityId[cityId])
  const isMunicipalitiesAvailable = !!municipalitiesAvailable.length

  return (
    <div className="layout">
      <div className="content">
        <Form
          form={form}
          layout="vertical"
          initialValues={filter}
          onValuesChange={handleFormChange}
          onFinish={handleFormSubmit}
        >
          <Divider
            orientation="left"
            orientationMargin="0"
            style={{
              color: 'var(--tg-theme-text-color)',
              marginTop: 0,
            }}
          >
            {t('filter.type.title')}
          </Divider>

          <Form.Item
            name="type"
            rules={[{ required: true, message: t('filter.type.message') }]}
          >
            <Checkbox.Group
              options={staticData.types}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>

          <Divider orientation="left" orientationMargin="0">
            <span style={{ color: 'var(--tg-theme-text-color)'}}>
              {t('filter.region.title')}
            </span>
          </Divider>

          <div className="region-wrapper">
            <Form.Item className="region-col-1">
              <Button.Group
                optionType="button"
                buttonStyle="dashed"
              >
                <Button onClick={() => setCityPreset(1)} type={filter.city.includes(1) ? 'primary' : 'default'}>
                  {staticData.cities[0].label}
                </Button>
                <Button onClick={() => setCityPreset(2)} type={filter.city.includes(2) ? 'primary' : 'default'}>
                  {staticData.cities[1].label}
                </Button>
                <Button onClick={() => setCityPreset(3)} type={filter.city.includes(3) ? 'primary' : 'default'}>
                  {staticData.cities[2].label}
                </Button>
              </Button.Group>
            </Form.Item>
            <Form.Item
              name="city"
              rules={[{ required: true, message: t('filter.region.message') }]}
              className="region-col-2"
            >
              <Select
                className="region"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.label.includes(input)}
                notFoundContent={t('filter.not-found')}
                mode="multiple"
                options={staticData.cities}
                virtual={false}
                onDropdownVisibleChange={(...args) => console.log(...args)}
              />
            </Form.Item>
          </div>

          {isMunicipalitiesAvailable && (
            <Form.Item
              style={{
                marginBottom: 0,
              }}
            >
              <Checkbox
                checked={allMunsChecked}
                onChange={(e) => {
                  setAllMunsChecked(e.target.checked)

                  if (e.target.checked) {
                    form.setFieldValue('municipality', null)
                    handleFormChange({municipality: []}, { ...form.getFieldsValue(true), municipality: [] })
                  }
                }}
              >
                {t('filter.municipalities.all')}
              </Checkbox>
            </Form.Item>
          )}

          {isMunicipalitiesAvailable && !allMunsChecked && (
            <>
              <Divider orientation="left" orientationMargin="0">
                {t('filter.municipalities.title')}
              </Divider>

              <Form.Item
                name="municipality"
              >
                <Checkbox.Group style={{ width: '100%' }}>
                  <Row style={{ width: '100%' }}>
                    {filter.city.map((cityId) => {
                      const muns = staticData.munByCityId[cityId]

                      if (!muns) return

                      const isManyMuns = municipalitiesAvailable.length > 1
                      const cityTitle = staticData.cities.find((c) => c.value === cityId).label

                      return (
                        <>
                          {muns.map((mun) => (
                            <Col xs={{ span: isManyMuns ? 24 : 12 }} sm={{ span: 8 }}>
                              <Checkbox
                                value={mun.value}
                              >
                                {isManyMuns && <Typography.Text type="secondary">{cityTitle} / </Typography.Text>}
                                {mun.label}
                              </Checkbox>
                            </Col>
                          ))}
                        </>
                      )
                    })}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </>
          )}

          <Divider orientation="left" orientationMargin="0">
            {t('filter.price.title')}
          </Divider>

          <Row>
            <Col span={12}>
              <Form.Item
                name="priceMin"
              >
                <InputNumber
                  placeholder={t('filter.min')}
                  min={0}
                  max={filter.priceMax}
                  style={{ width: 'calc(100% - 8px)' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="priceMax"
              >
                <InputNumber
                  placeholder={t('filter.max')}
                  min={filter.priceMin}
                  style={{ marginLeft: 8, width: 'calc(100% - 8px)' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="not-mobile" style={{ marginBottom: 16 }}>
            <Col span={24}>
              <Slider
                style={{
                  marginTop: -8,
                  marginBottom: 8,
                }}
                range
                min={100}
                max={5000}
                step={50}
                tooltip={{open: false}}
                value={[
                  typeof filter.priceMin === 'undefined' ? null : filter.priceMin,
                  typeof filter.priceMax === 'undefined' ? null : filter.priceMax,
                ]}
                onChange={([min, max]) => {
                  form.setFieldsValue({
                    priceMin: min,
                    priceMax: max,
                  })
                  handleFormChange({
                    priceMin: min,
                    priceMax: max,
                  }, {
                    ...form.getFieldsValue(true),
                    priceMin: min,
                    priceMax: max,
                  })
                }}
              />
            </Col>
          </Row>

          <Form.Item name="includePriceUnknown" valuePropName="checked" style={{ marginTop: -16 }}>
            <Checkbox className="hint-checkbox">{t('filter.price.unknown')}</Checkbox>
          </Form.Item>

          <div style={{ display: roomsAreHidden ? 'none' : 'block' }}>
            <Divider orientation="left" orientationMargin="0">
              {t('filter.rooms.title')}
            </Divider>

            <Form.Item
              name="rooms"
            >
              <Checkbox.Group>
                {rooms.map(r => (
                  <Checkbox value={r.value}>{t(`rooms.${r.label}`)}</Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>

            <Form.Item name="includeRoomsUnknown" valuePropName="checked" style={{ marginTop: -24 }}>
              <Checkbox className="hint-checkbox">{t('filter.rooms.unknown')}</Checkbox>
            </Form.Item>
          </div>

          <Form.Item style={{ display: additionalAreHidden ? 'block' : 'none' }}>
            <Button
              onClick={() => setAdditionalAreHidden(false)}
              size="large"
              type="dashed"
              block
            >
              {t('filter.more')}
            </Button>
          </Form.Item>
          <div style={{ display: additionalAreHidden ? 'none' : 'block' }}>
            <Divider orientation="left" orientationMargin="0">
              {t('filter.size.title')}
            </Divider>

            <Row>
              <Col span={12}>
                <Form.Item name="sizeMin">
                  <InputNumber
                    placeholder={t('filter.min')}
                    min={0}
                    style={{ width: 'calc(100% - 8px)' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="sizeMax"
                >
                  <InputNumber
                    placeholder={t('filter.max')}
                    min={0}
                    style={{ marginLeft: 8, width: 'calc(100% - 8px)' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="includeSizeUnknown" valuePropName="checked" style={{ marginTop: -16 }}>
              <Checkbox className="hint-checkbox">{t('filter.size.unknown')}</Checkbox>
            </Form.Item>

            <Divider orientation="left" orientationMargin="0">
              {t('filter.floor.title')}
            </Divider>

            <Row>
              <Col span={12}>
                <Form.Item
                  name="floorsMin"
                >
                  <InputNumber
                    placeholder={t('filter.min')}
                    min={1}
                    style={{ width: 'calc(100% - 8px)' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="floorsMax"
                >
                  <InputNumber
                    placeholder={t('filter.max')}
                    min={1}
                    style={{ marginLeft: 8, width: 'calc(100% - 8px)' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="includeFloorsUnknown" valuePropName="checked" style={{ marginTop: -16 }}>
              <Checkbox className="hint-checkbox">{t('filter.floor.unknown')}</Checkbox>
            </Form.Item>

            <Divider orientation="left" orientationMargin="0">
              {t('filter.furniture.title')}
            </Divider>

            <Form.Item
              name="furniture"
            >
              <Checkbox.Group style={{ width: '100%' }}>
                <Row style={{ width: '100%' }}>
                  {staticData.furniture.map(f => (
                    <Col span={24}>
                      <Checkbox value={f.value}>{f.label}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item name="includeFurnitureUnknown" valuePropName="checked" style={{ marginTop: -20 }}>
              <Checkbox className="hint-checkbox">{t('filter.furniture.unknown')}</Checkbox>
            </Form.Item>

            <Divider orientation="left" orientationMargin="0">
              {t('filter.heating.title')}
            </Divider>

            <Form.Item
              name="heating"
            >
              <Select
                optionFilterProp="children"
                mode="multiple"
                options={staticData.heating}
              />
            </Form.Item>

            <Form.Item name="includeHeatingUnknown" valuePropName="checked" style={{ marginTop: -16 }}>
              <Checkbox className="hint-checkbox">{t('filter.heating.unknown')}</Checkbox>
            </Form.Item>

            <Divider orientation="left" orientationMargin="0">
              {t('filter.pets-status.title')}
            </Divider>

            <Form.Item name="arePetsAllowed" valuePropName="checked">
              <Checkbox>
                {t('filter.pets-status.yes')}
              </Checkbox>
            </Form.Item>

            <Form.Item name="includePetsUnknown" valuePropName="checked" style={{ marginTop: -24 }}>
              <Checkbox className="hint-checkbox">
                {t('filter.pets-status.unknown')}
              </Checkbox>
            </Form.Item>

            <Divider orientation="left" orientationMargin="0">
              {t('filter.advertiser.title')}
            </Divider>

            <Form.Item
              name="advertiser"
            >
              <Radio.Group>
                {advertiser.map(r => (
                  <Radio value={r.value}>{t(`advertiser.${r.label}`)}</Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item name="includeAdvertiserUnknown" valuePropName="checked" style={{ marginTop: -24 }}>
              <Checkbox className="hint-checkbox">
                {t('filter.advertiser.unknown')}
              </Checkbox>
            </Form.Item>
          </div>

          {/*TODO: бля я хз, там какие=то лютые запросы*/}
          {/*<div className="counter-button">
            {filter.type.length > 0 && filter.city.length > 0 ? t('filter.messages.per-day', dailyCounter) : ''}
          </div>*/}
        </Form>
      </div>
    </div>
  )
}
