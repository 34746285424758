import { plural } from 'scraper-utils/helpers/plural.mjs'
import { tpl } from './helpers/tpl.mjs'

const keys = {
  ru: 0,
  en: 1,
  sr: 2,
  ua: 3,
}

export const translate = (strings, lang, key, data) => {
  if (strings[key] && typeof strings[key][keys[lang]] === 'string') {
    let str = strings[key][keys[lang]]

    if (str.includes('|')) {
      str = str.replaceAll('%', data)

      if (lang === 'en') {
        if (data === 1) {
          str = str.split('|')[0]
        } else {
          str = str.split('|')[1]
        }

        return str.replace('{}', data)
      }

      if (lang === 'sr' || lang === 'ru' || lang === 'ua') {
        return plural(data, ...str.split('|'))
      }
    }

    return tpl(strings[key][keys[lang]], data).trim().split('\n').map(l => l.trim()).join('\n')
  }

  return `{${key}}`
}

export const createTranslator = (strings, language) => {
  const t = (key, data = {}) => translate(strings, language, key, data)

  t.date = (date) => date.toLocaleDateString(language, { timeZone: 'Europe/Belgrade' })
  t.datetime = (date) => date.toLocaleString(language, { timeZone: 'Europe/Belgrade' })
  t.float = (float) => float.toLocaleString(language)

  return t
}
