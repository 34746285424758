export const uiStrings = {
  'filter.type.title': [
    'Тип жилья',
    'Housing type',
    'Tip smještaja',
    'Тип житла',
  ],
  'filter.type.message': [
    'Укажите тип жилья',
    'Specify the type of housing',
    'Odaberite tip smještaja',
    'Вкажіть тип житла',
  ],
  'filter.region.title': [
    'Регион',
    'Region',
    'Regija',
    'Регіон',
  ],
  'filter.region.message': [
    'Укажите хотя бы один регион',
    'Please enter at least one region',
    'Odaberite barem jednu regiju',
    'Вкажіть хоча б один регіон',
  ],
  'filter.municipalities.all': [
    'Все муниципалитеты',
    'All municipalities',
    'Svi gradovi i općine',
    'Усі муніципалітети',
  ],
  'filter.municipalities.title': [
    'Муниципалитеты',
    'Municipalities',
    'Općine',
    'Муніципалітети',
  ],
  'filter.price.title': [
    'Стоимость, €',
    'Rent, €',
    'Cijena, €',
    'Вартість, €',
  ],
  'filter.price.unknown': [
    'Показать объявления, если цена договорная',
    'Show ads if the price is negotiable',
    'Prikaži oglase ako je cijena po dogovoru',
    'Показати оголошення, якщо ціна договірна',
  ],
  'filter.rooms.title': [
    'Количество комнат',
    'Number of rooms',
    'Broj soba',
    'Кількість кімнат',
  ],
  'filter.rooms.unknown': [
    'Показать объявления, если количество комнат неизвестно',
    'Show ads if the number of rooms is unknown',
    'Prikaži oglase ako je broj soba nepoznat',
    'Показати оголошення, якщо кількість кімнат невідома',
  ],
  'filter.size.title': [
    'Площадь, м²',
    'Area, m²',
    'Površina, m²',
    'Площа, м²',
  ],
  'filter.size.unknown': [
    'Показать объявления, если площадь неизвестена',
    'Show ads if the area is unknown',
    'Prikaži oglase ako je površina nepoznata',
    'Показати оголошення, якщо площа невідома',
  ],
  'filter.floor.title': [
    'Этаж',
    'Floor',
    'Kat',
    'Поверх',
  ],
  'filter.floor.unknown': [
    'Показать объявления, если этаж неизвестен',
    'Show ads if floor is unknown',
    'Prikaži oglase ako je kat nepoznat',
    'Показати оголошення, якщо поверх невідомий',
  ],
  'filter.furniture.title': [
    'Мебель в помещении',
    'Indoor furniture',
    'Namještaj u prostoriji',
    'Меблі у приміщенні',
  ],
  'filter.furniture.unknown': [
    'Показать объявления, если информации о наличии мебели нет',
    'Show ads if there is no information about the availability of furniture',
    'Prikaži oglase ako nema informacija o namještaju',
    'Показати оголошення, якщо інформації про наявність меблів немає',
  ],
  'filter.heating.title': [
    'Отопление',
    'Heating',
    'Grijanje',
    'Опалення',
  ],
  'filter.heating.unknown': [
    'Показать объявления, если вид отопления неизвестен',
    'Show ads if type of heating is unknown',
    'Prikaži oglase ako je vrsta grijanja nepoznata',
    'Показати оголошення, якщо вид опалення невідомий',
  ],
  'filter.advertiser.title': [
    'Создатель объявления',
    'Ad Creator',
    'Autor oglasa',
    'Автор оголошення',
  ],
  'filter.advertiser.unknown': [
    'Показать объявления, если владелец объявления неизвестен',
    'Show ads if ad owner is unknown',
    'Prikaži oglase ako je vlasnik oglasa nepoznat',
    'Показати оголошення, якщо власник оголошення невідомий',
  ],
  'filter.min': [
    'от',
    'from',
    'od',
    'від',
  ],
  'filter.max': [
    'до',
    'to',
    'do',
    'до',
  ],
  'filter.not-found': [
    'Ничего не найдено',
    'Nothing found',
    'Ništa nije pronađeno',
    'Нічого не знайдено',
  ],
  'filter.more': [
    'Ещё параметры',
    'More options',
    'Više opcija',
    'Ще параметри',
  ],
  'filter.submit': [
    'Сохранить',
    'Save',
    'Spremi',
    'Зберегти',
  ],
  'filter.messages.per-day': [
    'Примерно % сообщение в день.|Примерно % сообщения в день.|Примерно % сообщений в день.',
    'Approximately % message per day.|Approximately % messages per day.',
    'Približno % poruka na dan.|Približno % poruke na dan.|Približno % poruka na dan.',
    'Приблизно % повідомлення на день.|Приблизно % повідомлення на день.|Приблизно % повідомленнь на день.',
  ],
  'filter.messages.to-many': [
    'Мы вам столько сообщений не пришлём, но выберем самые интересные!',
    'We will not send you so many messages, but we will choose the most interesting ones!',
    'Mi vam toliko poruka nećemo poslati, ali ćemo odabrati najzanimljivije!',
    'Ми вам стільки повідомлень не надішлемо, але виберемо найцікавіші!',
  ],
  'filter.pets-status.title': [
    'Домашние животные',
    'Pets',
    'Kućni ljubimci',
    'Домашні тварини',
  ],
  'filter.pets-status.yes': [
    'Можно с животными',
    'Pet-friendly',
    'Može sa kućnim ljubimcima',
    'Можна з тваринами',
  ],
  'filter.pets-status.unknown': [
    `Показать объявления, если отношение к домашним животным не указано`,
    `Show ads if the landlord's attitude towards pets is not specified`,
    `Prikaži oglase ako stav prema kućnim ljubimcima nije naveden`,
    `Показати оголошення, якщо ставлення до домашніх тварин не вказано`,
  ],
  'advertiser.None': [
    'Не важно',
    `Doesn't matter`,
    'Nije važno',
    'Неважливо',
  ],
  'advertiser.Owner': [
    'Владелец',
    'Owner',
    'Vlasnik',
    'Власник',
  ],
  'advertiser.Agency': [
    'Агентство',
    'Agency',
    'Agencija',
    'Агентство',
  ],
  'rooms.Studio': [
    'Студия',
    'Studio',
    'Studio',
    'Студія',
  ],
  'rooms.1': [
    '1',
    '1',
    '1',
    '1',
  ],
  'rooms.2': [
    '2',
    '2',
    '2',
    '2',
  ],
  'rooms.3': [
    '3',
    '3',
    '3',
    '3',
  ],
  'rooms.4+': [
    '4+',
    '4+',
    '4+',
    '4+',
  ],
}
