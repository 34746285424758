import './style.css'
import { Route } from 'wouter'
import { HomePage } from './pages/HomePage'
import { FilterPage } from './pages/FilterPage.js'

if (typeof window !== 'undefined') {
  const theme = document.querySelector('html').style.getPropertyValue('--tg-color-scheme')
  document.body.classList.add(`theme-${theme}`)
}

export default function App() {
  return (
    <>
      <Route path="/">
        <HomePage />
      </Route>
      <Route path="/filter/:editId/:externalId">
        {({ editId, externalId }) => (
          <FilterPage externalId={externalId} editId={editId} />
        )}
      </Route>
    </>
  )
}
